/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select } from "antd";

const CustomCurrencyField = ({
  currency,
  setCurrency,
  currenciesOptions,
  currenciesOptionsList,
  getCalculatedAmount,
}) => {
  const [label, setLabel] = useState(
    currenciesOptionsList.find((option) => option.value === currency)?.label
  );

  useEffect(() => {
    setLabel(
      currenciesOptionsList.find((option) => option.value === currency)?.label
    );
  }, [currenciesOptionsList]);

  const onChange = (value, options) => {
    setCurrency(value);
    setLabel(options.label);
    getCalculatedAmount({ value });
  };

  return (
    <>
      {currenciesOptionsList.length > 1 && (
        <div className="flex justify-end gap-0 pb-6">
          <div className="flex gap-0" id="currency">
            <div className="px-4 border border-[#d9d9d9] flex items-center text-sm rounded-l-md">
              {label}
            </div>
            <div className="w-fit" id="currency">
              <Select
                options={currenciesOptionsList}
                size="middle"
                onChange={onChange}
                value={currency}
                className="no-input w-[35px]"
                popupMatchSelectWidth={false}
                showSearch
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CustomCurrencyField;
