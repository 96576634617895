import PaymentStatusSuccess from "../StatusScreens/PaymentStatusSuccess";
import PaymentStatusError from "../StatusScreens/PaymentStatusError";
import usePaymentConfirmation from "../../hooks/OneTimePayment/usePaymentConfirmation";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";

const PaymentStatusView = ({ status }) => {
  const { triggerRedirect } = usePaymentConfirmation();
  const { loadingMain } = useOneTimePaymentsContext();

  switch (status) {
    case "SUCCESS":
      return (
        <PaymentStatusSuccess
          triggerRedirect={triggerRedirect}
          loadingMain={loadingMain}
        />
      );
    case "ERROR":
    default:
      return <PaymentStatusError />;
  }
};

export default PaymentStatusView;
