import React from "react";
import { ConfigProvider } from "antd";

const AntDesignContext = ({ children }) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              defaultActiveBg: "#000000",
              defaultBg: "#000000",
              defaultColor: "#ffffff",
              defaultHoverBg: "#000000",
              defaultHoverColor: "rgba(255, 255, 255, 0.8)",
            },
            Spin: {
              colorPrimary: "#000000",
            },
          },
        }}
      >
        {children}
      </ConfigProvider>
    </div>
  );
};

export default AntDesignContext;
