import axios from "axios";
import config from "../config";

const instance = axios.create({
  baseURL: config.api_path,
});

if (process.env.REACT_APP_XPAY_ENV === "dev") {
  instance.interceptors.request.use((req) => {
    return fetch("https://api.ipify.org?format=text")
      .then((response) => response.text())
      .then((ip) => {
        req.headers = {
          "x-forwarded-for": ip,
        };
        return req;
      });
  });
}

export default instance;
