/* eslint-disable react-hooks/exhaustive-deps */
import PaymentStatusSuccess from "./PaymentStatusSuccess";
import PaymentStatusFailed from "./PaymentStatusFailed";
import PaymentStatusRefunded from "./PaymentStatusRefunded";
import PaymentStatusView from "../../../OneTimePayment/PaymentStatusView";
import { useGetIntent } from "../../../../hooks/OneTimePayment/useGetIntent";
import GridBackground from "../../../UI/GridBackground";
import { ImSpinner2 } from "react-icons/im";
import SubscriptionStatusSuccess from "./SubscriptionStatusSuccess";
import Header from '../../../UI/Header';

const PaymentConfirmationScreen = () => {
  const urlParam = new URLSearchParams(window.location.search);
  const xIntentId = urlParam.get("xpay_intent_id");
  const subscriptionId = urlParam.get("subscription_id");

  const { status, loader } = useGetIntent({ xIntentId, subscriptionId });

  const renderContent = () => {
    if (xIntentId) {
      switch (status) {
        case "SUCCESS":
          return <PaymentStatusSuccess />;
        case "REFUNDED":
          return <PaymentStatusRefunded />;
        case "FAILED":
        case "INCOMPLETE":
        case "CREATED":
          return <PaymentStatusFailed />;
        case "LOADING":
        case "ERROR":
        default:
          return <PaymentStatusView id={xIntentId} />;
      }
    } else {
      switch (status) {
        case "ACTIVE":
        case "COMPLETED":
          return <SubscriptionStatusSuccess />;
        case "INACTIVE":
        case "INCOMPLETE":
        case "CREATED":
        case "CANCELLED":
          return <PaymentStatusFailed />;
        case "LOADING":
        case "ERROR":
        default:
          return <PaymentStatusView id={subscriptionId} />;
      }
    }
  };

  return (
    <GridBackground>
      <div
        className="bg-white h-full w-full max-w-[32rem] drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-6 px-6 backdrop-blur shadow-2xl "
        id="container"
      >
        {loader ? (
          <div className="flex items-center justify-center h-[80vh] text-3xl">
            <ImSpinner2 className="animate-spin" />
          </div>
        ) : (
          <>
          <Header />
          {renderContent()}</>
        )}
      </div>
    </GridBackground>
  );
};

export default PaymentConfirmationScreen;
