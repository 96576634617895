import Loader from "../../../UI/Loader";
import sucessV3 from "../../../../assets/Animations/sucessV3.json";

const SubscriptionStatusSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
      <Loader
        json={sucessV3}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="500px"
      />
      <div className="mb-8 font-sans text-xl">You are now subscribed!</div>
    </div>
  );
};

export default SubscriptionStatusSuccess;
