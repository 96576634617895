import Loader from "../../../UI/Loader";
import successAnimation from "../../../../assets/Animations/sucessV3.json";

const PaymentStatusSuccess = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
      <Loader
        json={successAnimation}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="500px"
      />
      <div className="mb-8 font-sans text-xl">Thank you for your payment!</div>
    </div>
  );
};

export default PaymentStatusSuccess;
