import React from "react";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import ChargeCard from "./../ChargeCard";
import StripeGateway from "../StripeGateway/StripeGateway";
import QuestionBox from "../../UI/QuestionBox";
import PaymentError from "../../UI/PaymentError";
import TestCardsContainer from "../../TestMode/TestCardsContainer";
import { stepsData } from "../../../constants/Steps";

const Payment = () => {
  const { intentDetails, globalIntentData, paymentError, mode, step } =
    useOneTimePaymentsContext();
  const renderPg = () => {
    switch (intentDetails.pg) {
      case "AUTHNET":
        return <ChargeCard />;
      case "STRIPE":
        return <StripeGateway />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {paymentError && <PaymentError paymentError={paymentError} />}
      {globalIntentData.paymentMethod === "CARD" ? (
        <QuestionBox step={step} stepsData={stepsData} />
      ) : (
        <div className="py-4"></div>
      )}{" "}
      {renderPg()}
      {mode === "TEST" &&
        step === stepsData.length - 1 &&
        globalIntentData.paymentMethod === "CARD" && (
          <TestCardsContainer pg={intentDetails.pg} />
        )}
    </>
  );
};

export default Payment;
