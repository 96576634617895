import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import axios from "../../axios";
import { paymentMethod } from "../../constants/paymentMethod";
import { ErrorHandler } from "../../utils/ErrorHandler";
import useTriggerIntent from "./useTriggerIntent";
import { useState } from "react";

const usePaymentMethod = () => {
  const {
    subscriptionId,
    setStatus,
    loadingMethods,
    setLoadingMethods,
    setPaymentMethod,
  } = useSubscriptionContext();

  const { triggerIntent } = useTriggerIntent();
  const [paymentMethodList, setPaymentMethodList] = useState();

  const getPaymentMethod = async (value) => {
    setLoadingMethods(true);
    try {
      const { data } = await axios.post(
        `/subscription/prefetch-payment-methods`,
        {
          subscriptionId: subscriptionId,
          currency: value,
        }
      );

      const paymentMethodOptions = [];

      data.paymentMethods.forEach((item) => {
        paymentMethodOptions.push(
          paymentMethod.find((method) => method.id === item)
        );
      });

      if (paymentMethodOptions.length === 1) {
        setPaymentMethod(paymentMethodOptions[0].value);
        await triggerIntent({
          value: paymentMethodOptions[0].value,
          currency: value,
        });
      }

      setPaymentMethodList(paymentMethodOptions);
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoadingMethods(false);
    }
  };

  return {
    getPaymentMethod,
    paymentMethodList,
    loadingMethods,
    setLoadingMethods,
  };
};

export default usePaymentMethod;
