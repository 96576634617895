/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { fieldNames } from "../../constants/fieldNames";
import cscIcon from "../../assets/Icons/card-security-code.png";
import { GoCreditCard } from "react-icons/go";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { Form } from "antd";
import ChargeCardInputField from "./../UI/FormFields/ChargeCardInputField";

const ChargeCard = () => {
  const {
    globalIntentData,
    setGlobalIntentData,
    CSCProps,
    setCSCProps,
    setIsPaymentBtnDisabled,
    setForm,
  } = useOneTimePaymentsContext();

  const [form] = Form.useForm();

  useEffect(() => {
    setIsPaymentBtnDisabled(false);
    setForm(form);
  }, []);

  useEffect(() => {
    form.setFieldsValue(globalIntentData);
  }, [globalIntentData]);

  return (
    <Form
      form={form}
      name={`ChargeCardDetails`}
      className="font-sans"
      layout="vertical"
      autoComplete="on"
      requiredMark={"optional"}
      initialValues={globalIntentData}
    >
      <ChargeCardInputField
        name={fieldNames.cardNumber}
        label="Card number"
        required
        placeholder={"1234 1234 1234 1234"}
        suffix={<GoCreditCard size={24} />}
        globalIntentData={globalIntentData}
        setGlobalIntentData={setGlobalIntentData}
        CSCProps={CSCProps}
        setCSCProps={setCSCProps}
      />
      <div className="grid grid-cols-2 gap-3">
        <ChargeCardInputField
          name={fieldNames.cardValidity}
          label="Expiration date"
          required
          placeholder={"MM/YY"}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
          CSCProps={CSCProps}
          setCSCProps={setCSCProps}
        />
        <ChargeCardInputField
          name={fieldNames.cardSecurityCode}
          label="Security code"
          required
          placeholder={"CSC"}
          suffix={<img src={cscIcon} alt="CSC Icon" className="w-6 " />}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
          CSCProps={CSCProps}
          setCSCProps={setCSCProps}
        />
      </div>
      <ChargeCardInputField
        name={fieldNames.cardName}
        label="Name on card"
        required
        placeholder={"Cardholder Name"}
        globalIntentData={globalIntentData}
        setGlobalIntentData={setGlobalIntentData}
        CSCProps={CSCProps}
        setCSCProps={setCSCProps}
      />
    </Form>
  );
};

export default ChargeCard;
