import React from "react";

const TnCBox = () => {
  return (
    <div>
      <div className="flex flex-col items-center justify-center w-full gap-4 px-2 py-4 text-xs text-center border rounded-md align-center">
        <p>
          This order process is conducted by our online reseller & Merchant of
          Record, xpaycheckout.com, who also handles order-related inquiries and
          returns.
        </p>
        <p className="opacity-50">
          Your data will be shared with Payport Inc. for product fulfilment.
          Payport Inc. 8 The Green, Ste A, Dover, Delaware - 19901, United
          States of America
        </p>
        <div className="opacity-50">
          <a
            href={`https://www.xpaycheckout.com/checkout-buyer-terms”`}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Terms & Conditions
          </a>
          <span>&nbsp;|&nbsp;</span>
          <a
            href={"https://www.xpaycheckout.com/privacy-policy"}
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Privacy
          </a>
        </div>
      </div>
    </div>
  );
};

export default TnCBox;
