/* eslint-disable react-hooks/exhaustive-deps */
import { Alert } from "antd";
import React from "react";

const PaymentError = ({paymentError}) => {
  return (
    <div id="alertBox">
      <Alert
        message={paymentError || "Something went wrong. Please try again."}
        type="error"
        showIcon
        className="font-sans"
      />
    </div>
  );
};

export default PaymentError;
