import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import StripeCheckoutForm from "./StripeCheckoutForm";

const StripeGateway = () => {
  const { intentDetails } = useOneTimePaymentsContext();

  const appearance = {
    theme: "stripe",
    variables: {
      borderRadius: "2px",
      tabIconSelectedColor: "#fff",
      gridRowSpacing: "24px",
      fontSizeSm: "14px",
    },
    rules: {
      ".Input, .Block, .CheckboxInput, .CodeInput": {
        boxShadow: "0px 0px 0px 0px",
        borderRadius: "8px",
      },
      ".Tab--selected, .Tab--selected:hover": {
        backgroundColor: "#fff",
      },
      ".Label": {
        paddingBottom: "12px",
        marginBottom: "0px",
      },
      ".Error": {
        color: "#ff4d4f",
      },
      ".Input--invalid": {
        borderColor: "#ff4d4f",
      },
    },
  };

  const options = {
    clientSecret: intentDetails.clientSecret,
    appearance: appearance,
  };

  const stripePromise = loadStripe(intentDetails.pgPublicKey);

  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckoutForm />
      </Elements>
    </div>
  );
};

export default StripeGateway;
