import { Button } from "antd";
import Loader from "../UI/Loader";
import failureAnimation from "../../assets/Animations/failureAnimation.json";

const SubscriptionFailedRetry = ({ message }) => {
  const handleRetry = () => {
    window.location.href = getPaymentUrl();
  };

  return (
    <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
      <Loader
        json={failureAnimation}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="320px"
      />
      <p className="pt-8 mb-8 text-lg text-center">
        {message || "Seems like the payment didn't go through!"}
      </p>
      <Button
        onClick={handleRetry}
        className="text-white bg-green-500 border-none shadow-md hover:bg-green-300"
      >
        RETRY
      </Button>
    </div>
  );
};

const getPaymentUrl = () => {
  const search = window.location.search;
  return `/${search}`;
};

export default SubscriptionFailedRetry;
