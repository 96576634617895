import visa from "../assets/cardsIcons/visa.png";
import mastercard from "../assets/cardsIcons/mastercard.png";
import dinersclub from "../assets/cardsIcons/dinersclub.png";
import americanExpress from "../assets/cardsIcons/americanExpress.png";
import discover from "../assets/cardsIcons/discover.png";
import jcb from "../assets/cardsIcons/jcb.png";
import { GoCreditCard } from "react-icons/go";

export const cardBrand = (card) => {
  switch (card) {
    case "visa":
      return <img src={visa} alt="visa icon" className="w-8" />;
    case "mastercard":
      return <img src={mastercard} alt="mastercard icon" className="w-6" />;
    case "diners-club":
      return <img src={dinersclub} alt="diners club icon" className="w-6" />;
    case "american-express":
      return (
        <img
          src={americanExpress}
          alt="american express icon"
          className="w-8"
        />
      );
    case "discover":
      return <img src={discover} alt="discover icon" className="w-8" />;
    case "jcb":
      return <img src={jcb} alt="jcb icon" className="w-6" />;
    default:
      return <GoCreditCard size={24} />;
  }
};
