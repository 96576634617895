export const stepsData = [
  {
    id: 0,
    title: "Your Details",
    question: "Please enter your details",
    description:
      "We collect this information to help combat fraud, and to keep your payment secure.",
  },
  {
    id: 1,
    title: "Method",
    question: "Please select your payment method",
    description:
      "Choose how you'd like to pay. We accept a variety of payment options for your convenience.",
  },
  {
    id: 2,
    title: "Payment",
    question: "Please enter your card details",
    description:
      "Enter your card details to complete the transaction. All payments are securely processed.",
  },
];
