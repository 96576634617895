/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Input, Form } from "antd";
import { getValidationRules } from "../../../utils/validations";
import { cardBrand } from "../../../constants/cards";
var valid = require("card-validator");

const InputField = ({
  label,
  name,
  required,
  placeholder,
  suffix,
  globalIntentData,
  setGlobalIntentData,
  CSCProps,
  setCSCProps,
}) => {
  const [suffixIcon, setSuffixIcon] = useState(suffix);
  const [maxLength, setMaxLength] = useState(320);
  const [minLength, setMinLength] = useState(0);

  function formatCreditCard(number, gaps) {
    let cleaned = number.replace(/\D/g, "");
    for (let i = 0; i < gaps.length; i++) {
      if (cleaned.length > gaps[i]) {
        cleaned =
          cleaned.slice(0, gaps[i] + i) + " " + cleaned.slice(gaps[i] + i);
      }
    }

    return cleaned;
  }

  useEffect(() => {
    if (name === "cardNumber") {
      const numberValidation = valid.number(globalIntentData?.cardNumber);
      if (numberValidation.card) {
        const gaps = numberValidation.card.gaps;
        const size =
          numberValidation.card.lengths[
            numberValidation.card.lengths.length - 1
          ];
        setMaxLength(size + gaps.length);
        setSuffixIcon(cardBrand(numberValidation.card.type));
        setCSCProps(numberValidation.card.code);
      }
    }
  }, [globalIntentData]);

  const onChange = (e) => {
    let value = e.target.value;

    if (name === "cardNumber") {
      const numberValidation = valid.number(value);
      if (numberValidation.card) {
        const gaps = numberValidation.card.gaps;
        const size =
          numberValidation.card.lengths[
            numberValidation.card.lengths.length - 1
          ];
        setMaxLength(size + gaps.length);

        setSuffixIcon(cardBrand(numberValidation.card.type));
        setCSCProps(numberValidation.card.code);
        value = formatCreditCard(value, gaps, size);
      } else {
        setSuffixIcon(cardBrand("default"));
      }
    } else if (name === "cardValidity") {
      if (value.length >= 2 && parseInt(value.slice(0, 2)) > 12) {
        value = "0" + value[0];
      }
      value = value.replace(/[^\d]/g, "");
      const matches = value.match(/.{1,2}/g);
      value = matches ? matches.join(" / ") : "";
      setMaxLength(7);
    } else if (name === "cardSecurityCode") {
      setMaxLength(CSCProps?.size || 3);
      setMinLength(CSCProps?.size || 3);
    }

    setGlobalIntentData({ ...globalIntentData, [name]: value });
  };

  return (
    <div>
      <Form.Item
        hasFeedback
        label={<div className="text-[#374151]">{label} </div>}
        name={name}
        validateFirst
        rules={getValidationRules(name, required, label)}
      >
        <Input
          size="large"
          name={name}
          placeholder={placeholder}
          value={globalIntentData[name]}
          onChange={onChange}
          suffix={suffixIcon}
          maxLength={maxLength}
          minLength={minLength}
        />
      </Form.Item>
    </div>
  );
};

export default InputField;
