/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { useEffect, useState } from "react";
import useGetPaymentMethod from "../../hooks/Subscription/usePaymentMethod";
import usePostIngestBillingAddress from "../../hooks/Subscription/usePostIngestBillingAddress";

const usePrefetchCurrencies = () => {
  const {
    subscriptionId,
    setCurrency,
    setStatus,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setInterval,
    setIntervalCount,
    setAmount,
    setPaymentMethod,
    setPg,
    setCallbackUrl,
    setPgPublicKey,
    setClientSecret,
    setSubscriptionId,
  } = useSubscriptionContext();

  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);
  const [globalIntentData, setGlobalIntentData] = useState();

  const { getPaymentMethod, paymentMethodList } = useGetPaymentMethod();
  const { postIngestBillingAddress } = usePostIngestBillingAddress();

  const urlParam = new URLSearchParams(window.location.search);
  const clientSecret = urlParam.get("payment_intent_client_secret");

  const getCurrencySymbol = (currency) => {
    let symbol = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(0);

    symbol = symbol.replace(/\d/g, "");

    return currency === symbol.slice(0, -1)
      ? currency
      : `${currency} (${symbol})`;
  };

  useEffect(() => {
    const getPrefetchCurrencies = async () => {
      setLoadingMain(true);
      try {
        const { data } = await axios.post(
          `/subscription/prefetch-info/${subscriptionId}`
        );

        setAmount(data?.primary?.amount);
        setCurrency(data?.primary?.currency);
        setMerchantName(data?.merchantName);
        setMode(data?.mode);
        setInterval(data?.interval.toLowerCase());
        setIntervalCount(data?.intervalCount);
        setCallbackUrl(data?.callbackUrl);

        setGlobalIntentData({
          ...globalIntentData,
          [fieldNames.name]: data?.name,
          [fieldNames.email]: data?.email,
          [fieldNames.pincode]: data?.pinCode,
          [fieldNames.country]: data?.country
            ? data?.country +
              "-" +
              Country.getCountryByCode(data?.country)?.name
            : null,
        });

        let currenciesOptionsList = [];

        currenciesOptionsList.push({
          label: getCurrencySymbol(data.primary.currency),
          value: data.primary.currency,
        });

        data.others.forEach((element) => {
          currenciesOptionsList.push({
            label: getCurrencySymbol(element),
            value: element,
          });
        });

        setCurrenciesOptionsList(currenciesOptionsList);

        if (clientSecret) {
          setPaymentMethod(urlParam.get("paymentMethod"));
          setCurrency(urlParam.get("currency"));
          setAmount(urlParam.get("amount"));
          setPg("STRIPE");
          setClientSecret(clientSecret);
          setPgPublicKey(urlParam.get("pgPublicKey"));
          setSubscriptionId(urlParam.get("subscription_id"));
          setCallbackUrl(urlParam.get("callbackUrl"));
        }

        await getPaymentMethod(
          urlParam.get("currency") || data?.primary?.currency
        );

        if (data?.name && data?.email && data?.pinCode && data?.country) {
          const values = {
            [fieldNames.name]: data?.name,
            [fieldNames.email]: data?.email,
            [fieldNames.pincode]: data?.pinCode,
            [fieldNames.country]: data?.country,
          };

          await postIngestBillingAddress(values, false);
        }

        setLoading(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };
    getPrefetchCurrencies();
  }, []);

  return {
    merchantName,
    currenciesOptionsList,
    globalIntentData,
    setGlobalIntentData,
    getPaymentMethod,
    paymentMethodList,
  };
};

export default usePrefetchCurrencies;
