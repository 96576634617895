import React from "react";
import GridBackground from "./components/UI/GridBackground";
import Container from "./components/OneTimePayment/Container";
import { OneTimePaymentsProvider } from "./contexts/OneTimePaymentsContext";

const OneTimePayments = () => {
  return (
    <div>
      <GridBackground>
        <OneTimePaymentsProvider>
          <Container />
        </OneTimePaymentsProvider>
      </GridBackground>
    </div>
  );
};

export default OneTimePayments;
