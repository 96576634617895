import React from "react";
import InputField from "../UI/FormFields/InputField";
import { fieldNames } from "../../constants/fieldNames";
import cscIcon from "../../assets/Icons/card-security-code.png";
import { GoCreditCard } from "react-icons/go";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";

const ChargeCard = () => {
  const { globalIntentData, setGlobalIntentData, CSCProps, setCSCProps } =
    useOneTimePaymentsContext();

  return (
    <>
      <div className="flex flex-col">
        <InputField
          name={fieldNames.cardNumber}
          label="Card number"
          required
          placeholder={"1234 1234 1234 1234"}
          suffix={<GoCreditCard size={24} />}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
          CSCProps={CSCProps}
          setCSCProps={setCSCProps}
        />
        <div className="grid grid-cols-2 gap-6">
          <InputField
            name={fieldNames.cardValidity}
            label="Expiration date"
            required
            placeholder={"MM/YY"}
            globalIntentData={globalIntentData}
            setGlobalIntentData={setGlobalIntentData}
            CSCProps={CSCProps}
            setCSCProps={setCSCProps}
          />
          <InputField
            name={fieldNames.cardSecurityCode}
            label="Security code"
            required
            placeholder={"CSC"}
            suffix={<img src={cscIcon} alt="CSC Icon" className="w-6 " />}
            globalIntentData={globalIntentData}
            setGlobalIntentData={setGlobalIntentData}
            CSCProps={CSCProps}
            setCSCProps={setCSCProps}
          />
        </div>
        <InputField
          name={fieldNames.cardName}
          label="Name on card"
          required
          placeholder={"Cardholder Name"}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
          CSCProps={CSCProps}
          setCSCProps={setCSCProps}
        />
      </div>
    </>
  );
};

export default ChargeCard;
