import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { toast } from 'react-hot-toast';
import SuccessToast from './../../components/UI/Toasts/SuccessToast';

const usePaymentMethod = () => {
  const { subscriptionId, setStatus } = useSubscriptionContext();

  const [loading, setLoading] = useState(false);

  const postIngestBillingAddress = async (values, showToast) => {
    setLoading(true);
    try {
      await axios.post(`/subscription/ingest-billing-address`, {
        subscriptionId: subscriptionId,
        billingDetails: {
          name: values?.name,
          email: values?.email,
          customerAddress: {
            postalCode: values?.pincode,
            country: values?.country.split("-")[0],
          },
        },
      });

      if (showToast) {
        toast.custom((t) => (
          <SuccessToast t={t} message="Billing details updated successfully!" />
        ));
      }
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { loading, postIngestBillingAddress };
};

export default usePaymentMethod;
