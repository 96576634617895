import SubscriptionStatusSuccess from "../StatusScreens/SubscriptionStatusSuccess";
import PaymentStatusError from "../StatusScreens/PaymentStatusError";
import usePaymentConfirmation from "../../hooks/Subscription/usePaymentConfirmation";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import SubscriptionFailedRetry from "../StatusScreens/SubscriptionFailedRetry";

const PaymentStatusView = ({ status, errorMessage }) => {
  const { triggerRedirect } = usePaymentConfirmation();
  const { loadingMain } = useSubscriptionContext();

  switch (status) {
    case "COMPLETED":
    case "ACTIVE":
      return (
        <SubscriptionStatusSuccess
          triggerRedirect={triggerRedirect}
          loadingMain={loadingMain}
        />
      );
    case "CREATED":
    case "INCOMPLETE":
    case "INACTIVE":
      return <SubscriptionFailedRetry message={errorMessage} />;
    default:
      return <PaymentStatusError />;
  }
};

export default PaymentStatusView;
