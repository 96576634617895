import Loader from "../UI/Loader";
import sww500Animations from "../../assets/Animations/somethingwentwrong.json";
import { Button } from "antd";
import { IoRefreshOutline } from "react-icons/io5";

const PaymentStatusError = ({ id }) => {
  const handleRefresh = () => {
    window.location.reload();
  };

  return (
    <div className="flex flex-col items-center justify-center gap-12 h-[75vh] drop-shadow-xl">
      <Loader
        json={sww500Animations}
        width="400px"
        height="200px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="200px"
        mobileWidth="300px"
      />
      <div className="">
        <p className="text-xl text-center">Oops, something went wrong!</p>
        <p className="mt-1 text-sm text-center text-[#838383]">
          Give it a refresh, and we’ll get things back on track.
        </p>
      </div>
      <div className="flex gap-2 px-3 py-2 text-xs rounded-lg border border-[#EBEBEB]">
        <div className="font-medium">Ref Id:</div>
        <div className="text-[#767676]">{id}</div>
      </div>
      <Button
        size="large"
        className="font-sans "
        block
        onClick={handleRefresh}
        icon={<IoRefreshOutline />}
      >
        Refresh
      </Button>
    </div>
  );
};

export default PaymentStatusError;
