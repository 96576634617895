import React, { useState, useEffect } from "react";
import InputField from "../../UI/FormFields/InputField";
import { fieldNames } from "../../../constants/fieldNames";
import { Country } from "country-state-city";
import { forEach } from "lodash";
import SelectField from "../../UI/FormFields/SelectField";
import QuestionBox from "../../UI/QuestionBox";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
import { stepsData } from "../../../constants/Steps";

const YourDetails = () => {
  const [countries, setCountries] = useState([]);
  const { step, globalIntentData, setGlobalIntentData, CSCProps, setCSCProps } =
    useSubscriptionContext();

  useEffect(() => {
    const countryData = Country.getAllCountries();
    let countryOptions = [];
    forEach(countryData, (country) => {
      countryOptions.push({
        label: country.flag + "  " + country.name,
        value: country.isoCode + "-" + country.name,
      });
    });

    setCountries(countryOptions);
  }, []);

  return (
    <>
      <QuestionBox step={step} stepsData={stepsData} />
      <div className="flex flex-col">
        <InputField
          name={fieldNames.email}
          label="Email"
          required
          placeholder={"Enter your email"}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
          CSCProps={CSCProps}
          setCSCProps={setCSCProps}
        />
        <SelectField
          name={fieldNames.country}
          label="Country"
          placeholder="Select your country"
          required
          options={countries}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
        />
        <InputField
          name={fieldNames.pincode}
          label="ZIP/Postcode"
          required={false}
          placeholder={"Enter your postal code"}
          globalIntentData={globalIntentData}
          setGlobalIntentData={setGlobalIntentData}
          CSCProps={CSCProps}
          setCSCProps={setCSCProps}
        />
      </div>
    </>
  );
};

export default YourDetails;
