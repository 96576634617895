import React from "react";
import { Button } from "antd";
import useTriggerPayment from "../../hooks/OneTimePayment/useTriggerPayment";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";

const ProceedButton = () => {
  const { triggerPayment, payBtnLoading } = useTriggerPayment();
  const { isPaymentBtnDisabled } = useOneTimePaymentsContext();

  const handlePay = async () => {
    await triggerPayment();
  };

  return (
    <div className="sm:mt-6 mt-4">
      <Button
        size="large"
        className="font-sans font-semibold"
        block
        onClick={handlePay}
        loading={payBtnLoading}
        disabled={isPaymentBtnDisabled}
      >
        Pay
      </Button>
    </div>
  );
};

export default ProceedButton;
