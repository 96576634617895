import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";
import axios from "../../axios";

const usePaymentConfirmation = () => {
  const {
    setStatus,
    setPaymentError,
    setLoading,
    setCurrency,
    subscriptionId,
    callbackUrl
  } = useSubscriptionContext();

  const stripeConfirmation = async ({ stripe }) => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    setLoading(true);

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      if (!paymentIntent) {
        return;
      }
      if (paymentIntent.status === "succeeded") {
        setStatus("SUCCESS");
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    });

    await collectStripeCallbackData();
    clearUrlParams();

    setLoading(false);
  };

  const authNetConfirmation = () => {};

  const collectStripeCallbackData = async () => {
    try {
      await axios.post(`/subscription/trigger-ack/${subscriptionId}`, {});
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  const triggerRedirect = async () => {
    try {
      if (!callbackUrl) {
        ErrorHandler(new Error("callbackUrl not found"), setStatus);
        return;
      }
      const callback = new URL(callbackUrl);
      callback.searchParams.append(
        "subscription_id",
        subscriptionId
      );
      setTimeout(() => window.location.replace(callback.href), 5000);
    } catch (error) {
      ErrorHandler(error, setStatus);
    }
  };

  const clearUrlParams = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    setCurrency(searchParams.get("currency"));
    searchParams.delete("currency");
    searchParams.delete("paymentMethod");
    searchParams.delete("payment_intent");
    searchParams.delete("pgPublicKey");
    searchParams.delete("payment_intent_client_secret");
    searchParams.delete("redirect_status");
    searchParams.delete("callbackUrl");
    url.search = searchParams.toString();
    window.history.replaceState({}, "", url.toString());
  };

  return {
    stripeConfirmation,
    authNetConfirmation,
    triggerRedirect,
    collectStripeCallbackData,
  };
};

export default usePaymentConfirmation;
