import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import usePaymentConfirmation from "./usePaymentConfirmation";
import { useState } from "react";

const useTriggerPayment = () => {
  const {
    intentDetails,
    globalIntentData,
    xIntentId,
    stripe,
    stripeElement,
    setPaymentError,
    setStatus,
    currency,
  } = useOneTimePaymentsContext();
  const { collectStripeCallbackData } = usePaymentConfirmation();

  const [payBtnLoading, setPayBtnLoading] = useState(false);

  const triggerPayment = async () => {
    switch (intentDetails.pg) {
      case "AUTHNET":
        await chargeCard();
        break;
      case "STRIPE":
        await stripePay();
        break;
      default:
        break;
    }
  };

  const chargeCard = async () => {
    const cardValidity = globalIntentData?.cardValidity.split(" / ");
    const cardNumber = globalIntentData?.cardNumber.replace(/\s/g, "");
    const cardDetails = {
      cardNumber: cardNumber,
      cardHolderName: globalIntentData?.cardName,
      expiryMonth: cardValidity[0],
      expiryYear: cardValidity[1],
      cardCode: globalIntentData?.cardSecurityCode,
    };

    setPayBtnLoading(true);

    try {
      const { data } = await axios.post(`/payments/charge-card`, {
        xIntentId: xIntentId,
        cardDetails: cardDetails,
      });

      if (data?.status === "SUCCESS") {
        setStatus("SUCCESS");
      } else {
        setPaymentError(data?.errorMessage);
      }
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setPayBtnLoading(false);
    }
  };

  const stripePay = async () => {
    const elements = stripeElement;
    if (!stripe || !elements) {
      return;
    }

    setPayBtnLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url:
          window.location.href +
          `&callbackUrl=${intentDetails.callbackUrl}&paymentMethod=${globalIntentData.paymentMethod}&currency=${currency}&pgPublicKey=${intentDetails.pgPublicKey}`,
      },
      redirect: "if_required",
    });

    await collectStripeCallbackData();

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    } else if (paymentIntent) {
      if (paymentIntent.status === "succeeded") {
        setStatus("SUCCESS");
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    }

    setPayBtnLoading(false);
  };

  return { triggerPayment, payBtnLoading };
};

export default useTriggerPayment;
