/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { useEffect, useState } from "react";

const usePrefetchCurrencies = () => {
  const {
    subscriptionId,
    currency,
    setCurrency,
    setAmount,
    setStatus,
    globalIntentData,
    setGlobalIntentData,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setInterval,
    setIntervalCount,
    setCallbackUrl,
  } = useSubscriptionContext();

  const [currenciesOptions, setCurrenciesOptions] = useState([]);
  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);

  const getCurrencySymbol = (currency) => {
    let symbol = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(0);

    symbol = symbol.replace(/\d/g, "");

    return currency === symbol.slice(0, -1)
      ? currency
      : `${currency} (${symbol})`;
  };

  useEffect(() => {
    const getPrefetchCurrencies = async () => {
      setLoadingMain(true);
      try {
        const { data } = await axios.post(
          `/subscription/prefetch-info/${subscriptionId}`
        );

        setCurrency(data?.primary?.currency);
        setAmount(data?.primary?.amount);
        setMerchantName(data?.merchantName);
        setMode(data?.mode);
        setInterval(data?.interval.toLowerCase());
        setIntervalCount(data?.intervalCount);
        setCallbackUrl(data?.callbackUrl);

        let currenciesOptionsList = [];
        let currenciesOptions = [];

        setGlobalIntentData({
          ...globalIntentData,
          [fieldNames.email]: data?.email,
          [fieldNames.name]: data?.name,
          [fieldNames.pincode]: data?.pinCode,
          [fieldNames.country]: data?.country
            ? data?.country +
              "-" +
              Country.getCountryByCode(data?.country)?.name
            : null,
        });

        currenciesOptions.push({
          label: getCurrencySymbol(data.primary.currency),
          value: data.primary.currency,
        });

        currenciesOptionsList.push({
          label: getCurrencySymbol(data.primary.currency),
          value: data.primary.currency,
        });

        data.others.forEach((element) => {
          currenciesOptionsList.push({
            label: getCurrencySymbol(element),
            value: element,
          });
        });

        setCurrenciesOptions(currenciesOptions);
        setCurrenciesOptionsList(currenciesOptionsList);

        setLoading(false);
        await new Promise((resolve) => setTimeout(resolve, 100));
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };
    getPrefetchCurrencies();
  }, []);

  return {
    currency,
    merchantName,
    currenciesOptions,
    currenciesOptionsList,
    setCurrenciesOptions,
  };
};

export default usePrefetchCurrencies;
