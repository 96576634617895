import React from "react";

// Icons
import { MdOutlineMode } from "react-icons/md";
import { ReactComponent as UserTick } from "../../assets/Icons/Check-filled.svg";

const UserBox = ({ open, setOpen, globalIntentData }) => {
  const showDrawer = () => {
    setOpen(true);
  };

  const maxCharacters =
    window.innerWidth > 512 ? 40 : (window.innerWidth - 176) / 8;

  return (
    <div className="flex items-center justify-between gap-2 px-4 py-2 border border-gray-200 rounded-lg sm:p-4">
      <div>
        <div className="flex items-center gap-2 font-medium">
          <span>
            {globalIntentData?.name.length > maxCharacters
              ? globalIntentData?.name.slice(0, maxCharacters - 5) + "..."
              : globalIntentData?.name}
          </span>{" "}
          <UserTick />
        </div>
        <div className="text-[#535353] font-normal hidden sm:block">
          {globalIntentData?.email.length +
            globalIntentData?.country.length +
            globalIntentData?.pincode.length >
          maxCharacters ? (
            <div>
              {globalIntentData?.email.length > maxCharacters
                ? globalIntentData?.email.slice(0, maxCharacters) + "..."
                : globalIntentData?.email}
              <br />
              {globalIntentData?.pincode.length +
                globalIntentData?.country.length >
              maxCharacters
                ? `${globalIntentData?.pincode}, ${globalIntentData?.country
                    .split("-")[1]
                    .slice(
                      0,
                      maxCharacters - globalIntentData?.pincode.length
                    )}...`
                : globalIntentData?.pincode +
                  ", " +
                  globalIntentData?.country.split("-")[1]}
            </div>
          ) : (
            `${globalIntentData?.email} • ${globalIntentData?.pincode}, ${
              globalIntentData?.country.split("-")[1]
            }`
          )}
        </div>
      </div>
      <div
        className="rounded-full bg-[#F1F1F1] w-[25px] h-[25px] sm:w-[40px] sm:h-[40px] flex items-center justify-center cursor-pointer"
        onClick={showDrawer}
      >
        <MdOutlineMode className="sm:text-2xl " />
      </div>
    </div>
  );
};

export default UserBox;
