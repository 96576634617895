/* eslint-disable react-hooks/exhaustive-deps */
import { Divider } from "antd";
import CustomCurrencyField from "../UI/CustomCurrencyField";
import { ImSpinner2 } from "react-icons/im";
import useCalculateAmount from "../../hooks/Subscription/useCalculateAmount";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";

const TransDetails = ({
  merchantName,
  currenciesOptions,
  currenciesOptionsList,
}) => {
  const { step, interval, intervalCount, currency, setCurrency } =
    useSubscriptionContext();
  const { amountLoading, amount, getCalculatedAmount } = useCalculateAmount();

  return (
    <div>
      <Divider />
      {step === 0 && (
        <CustomCurrencyField
          currency={currency}
          setCurrency={setCurrency}
          currenciesOptions={currenciesOptions}
          currenciesOptionsList={currenciesOptionsList}
          getCalculatedAmount={getCalculatedAmount}
        />
      )}
      <div className="flex flex-col gap-1 justify-right">
        <div className="flex items-center justify-between gap-4">
          <div>
            <span className="">Paying to</span>{" "}
            <span className="font-semibold">{merchantName}</span>
          </div>
          <div className="text-right">
            {amountLoading ? (
              <div className="flex items-center justify-center text-sm">
                <ImSpinner2 className="mr-2 animate-spin" />
                <span className="animate-pulse">Calculating...</span>
              </div>
            ) : (
              <div className="font-semibold">
                {Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: currency,
                  minimumFractionDigits: 2,
                }).format(amount / 100)}
              </div>
            )}
          </div>
        </div>
        <div className="text-sm text-right text-gray-600">
          Recurring every {intervalCount} {interval}
          {intervalCount > 1 ? "s" : ""}
        </div>
      </div>
    </div>
  );
};

export default TransDetails;
