/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Header from "../UI/Header.jsx";
import StepContent from "./StepContent.jsx";
import ProceedButton from "./ProceedButton.jsx";
import TnCBox from "../UI/TnCBox.jsx";
import TransDetails from "./TransDetails.jsx";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext.jsx";
import { Spin } from "antd";
import { ImSpinner2 } from "react-icons/im";
import PaymentStatusView from "./PaymentStatusView.jsx";
import Breadcrumb from "../UI/Breadcrumb.jsx";
import usePaymentMethod from "../../hooks/OneTimePayment/usePaymentMethod.js";
import { stepsData } from "../../constants/Steps.js";
import usePrefetchCurrencies from "../../hooks/OneTimePayment/usePrefetchCurrencies.js";
import { useGetMerchantLogo } from "../../hooks/OneTimePayment/useGetMerchantLogo.js";

const Container = () => {
  const {
    xIntentId,
    step,
    loading,
    status,
    setStatus,
    setStep,
    intentDetails,
    setIntentDetails,
    loadingMain,
    setLoadingMain,
    setCurrency,
    mode,
    callbackUrl
  } = useOneTimePaymentsContext();
  const { getPaymentMethod } = usePaymentMethod();
  const urlParam = new URLSearchParams(window.location.search);
  const clientSecret = urlParam.get("payment_intent_client_secret");
  const {
    currency,
    merchantName,
    currenciesOptions,
    currenciesOptionsList,
    setCurrenciesOptions,
  } = usePrefetchCurrencies();
  const { merchantLogo } = useGetMerchantLogo({
    xIntentId,
    setLoadingMain,
    setStatus,
  });

  const stripeConfirmation = async () => {
    setStep(stepsData.length - 1);
    await getPaymentMethod();
  };

  useEffect(() => {
    if (clientSecret) {
      setIntentDetails({
        ...intentDetails,
        pg: "STRIPE",
        clientSecret: clientSecret,
        pgPublicKey: urlParam.get("pgPublicKey"),
        xIntentId: urlParam.get("xpay_intent_id"),
        callbackUrl: urlParam.get("callbackUrl"),
      });
      stripeConfirmation();
    }
  }, []);

  const referrer = document.referrer;
  const stimulation =
    referrer === "https://poc.xpaycheckout.com/" || // Prod (Test Mode) Env
    referrer === "http://localhost:5173/"; // Dev Env

  return (
    <Spin
      spinning={loadingMain}
      indicator={<ImSpinner2 className="animate-spin" />}
      size="large"
    >
      <Header
        setLoadingMain={setLoadingMain}
        mode={mode}
        merchantLogo={merchantLogo}
        callbackUrl={callbackUrl}
        xIntentId={xIntentId}
      />
      {status ? (
        <PaymentStatusView status={status} />
      ) : (
        <>
          <Breadcrumb step={step} setStep={setStep} stepsData={stepsData} />
          <Spin
            spinning={loading}
            indicator={<ImSpinner2 className="animate-spin" />}
            size="large"
          >
            <StepContent />
            <TransDetails
              currency={currency}
              setCurrency={setCurrency}
              merchantName={merchantName}
              currenciesOptions={currenciesOptions}
              currenciesOptionsList={currenciesOptionsList}
              setCurrenciesOptions={setCurrenciesOptions}
            />
            <ProceedButton />
            {stimulation && <TnCBox />}
          </Spin>
        </>
      )}
    </Spin>
  );
};

export default Container;
