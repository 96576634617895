/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Select } from "antd";

const CustomCurrencyField = ({
  currency,
  setCurrency,
  currenciesOptionsList,
  getCalculatedAmount,
  getPaymentMethod,
  setIntentDetails,
  setPaymentError,
  setIsPaymentBtnDisabled,
}) => {
  const [label, setLabel] = useState(
    currenciesOptionsList.find((option) => option.value === currency)?.label
  );

  useEffect(() => {
    setLabel(
      currenciesOptionsList.find((option) => option.value === currency)?.label
    );
  }, [currency, currenciesOptionsList]);

  const onChange = (value, options) => {
    setCurrency(value);
    setLabel(options.label);
    setIntentDetails(null);
    setPaymentError(null);
    setIsPaymentBtnDisabled(true);
    getCalculatedAmount({ value });
    getPaymentMethod(value);
  };

  return (
    <>
      {currenciesOptionsList.length > 1 && (
        <div className="flex" id="currency">
          <div className="px-2 border border-[#d9d9d9] flex items-center justify-center text-sm rounded-l-md w-[92px]">
            {label}
          </div>
          <Select
            options={currenciesOptionsList}
            size={"large"}
            onChange={onChange}
            value={currency}
            className="no-input w-[40px]"
            popupMatchSelectWidth={false}
            showSearch
          />
        </div>
      )}
    </>
  );
};

export default CustomCurrencyField;
