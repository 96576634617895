import Loader from "../../../UI/Loader";
import completedAnimation from "../../../../assets/Animations/paymentCompleted.json";

// Used same as `PaymentCompleted` For REFUNDED Status as of now (might change in future)
const PaymentStatusRefunded = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
      <Loader
        json={completedAnimation}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="250px"
        mobileWidth="500px"
      />
      <div className="mb-8 font-sans text-xl">Your Payment is Completed</div>
    </div>
  );
};

export default PaymentStatusRefunded;
