import toast from "react-hot-toast";
import axios from "../../axios";
import { useState } from "react";
import ErrorToast from './../../components/UI/Toasts/ErrorToast';

export const useAMACreateIntent = () => {
  const [loading, setLoading] = useState(false);

  const createAMAIntent = async (values) => {
    setLoading(true);
    try {
      const { data } = await axios.post("/ama/create-intent", {
        amount: values.amount * 100,
        currency: "INR",
        name: values.name,
        email: values.email,
      });

      window.location.href = data.fwdUrl;
    } catch (error) {
      toast.custom(
        (t) => (
          <ErrorToast
            t={t}
            message="Something went wrong. Please try again later."
          />
        ),
        {
          id: "errorToast",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  return { createAMAIntent, loading };
};
