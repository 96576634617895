import React from "react";
import { Radio, Form } from "antd";
import { getValidationRules } from "../../../utils/validations";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import { fieldNames } from "../../../constants/fieldNames";
import QuestionBox from "../../UI/QuestionBox";
import { stepsData } from "./../../../constants/Steps";

const name = fieldNames.paymentMethod;
const label = "Payment Method";
const required = true;

const Method = () => {
  const { paymentMethodList, globalIntentData, setGlobalIntentData, step } =
    useOneTimePaymentsContext();

  const onChange = (e) => {
    setGlobalIntentData({
      ...globalIntentData,
      [fieldNames.paymentMethod]: e.target.value,
    });
  };

  return (
    <>
      <QuestionBox step={step} stepsData={stepsData} />
      <div className="flex items-center justify-between pt-4" id="method">
        <Form.Item
          hasFeedback
          name={name}
          validateFirst
          rules={getValidationRules(name, required, label)}
        >
          <Radio.Group
            name={name}
            onChange={onChange}
            value={globalIntentData[fieldNames.paymentMethod]}
            required={required}
          >
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
              {paymentMethodList.map((option) => {
                return (
                  <Radio.Button
                    value={option.value}
                    className={`flex items-center justify-center min-w-[111px] min-h-[96px] h-full p-4 transition-all duration-300
                      ${
                        globalIntentData[fieldNames.paymentMethod] ===
                        option.value
                          ? "drop-shadow-xl bg-blue-50 ring-2 ring-blue-500"
                          : ""
                      }`}
                    key={option.value}
                  >
                    <div className="flex flex-col items-center justify-center gap-2 font-medium">
                      <span className="text-4xl">{option.icon}</span>
                      <span className="text-sm text-center">
                        {option.title}
                      </span>
                    </div>
                  </Radio.Button>
                );
              })}
            </div>
          </Radio.Group>
        </Form.Item>
      </div>
    </>
  );
};

export default Method;
