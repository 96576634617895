import React from "react";
import { paymentMethod as paymentMethodOptions } from "./../../constants/paymentMethod";
import { FaAngleDown } from "react-icons/fa6";
import { toast } from "react-hot-toast";
import InfoToast from "./Toasts/InfoToast";

const PaymentDetails = ({
  intentDetails,
  setIntentDetails,
  paymentMethod,
  paymentMethodList,
  setPaymentError,
  setIsPaymentBtnDisabled,
}) => {
  const method = paymentMethodOptions.find(
    (item) => item.value === paymentMethod
  )?.title;

  return (
    <div className="flex flex-wrap items-center gap-2">
      <div className="text-[16px] font-medium sm:text-[20px]">
        {!intentDetails ? "Payment Methods" : "Paying via"}
      </div>
      {intentDetails && (
        <div
          className="px-3 py-1 text-sm bg-[#E9EDFF] text-[#3F5BE5] rounded-full flex gap-2 items-center cursor-pointer"
          onClick={() => {
            if (paymentMethodList.length === 1) {
              toast.custom(
                (t) => (
                  <InfoToast
                    t={t}
                    message={`Only ${method.toLowerCase()} payment method is available for this currency.`}
                  />
                ),
                {
                  id: "paymentMethod",
                }
              );
              return;
            }
            setIntentDetails(null);
            setPaymentError(null);
            setIsPaymentBtnDisabled(true);
          }}
        >
          {method}
          <FaAngleDown size={12} />
        </div>
      )}
    </div>
  );
};

export default PaymentDetails;
