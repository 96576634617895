import { fieldNames } from "../constants/fieldNames";
const valid = require("card-validator");

export const getValidationRules = (name, required, label) => {
  const baseRules = [
    {
      required,
      message: `Please input your ${label.toLowerCase()}!`,
    },
  ];

  switch (name) {
    case fieldNames.email:
      return [
        ...baseRules,
        {
          type: "email",
          message: "Please enter a valid email address!",
        },
      ];
    case fieldNames.cardNumber:
      return [
        ...baseRules,
        {
          validator: async (_, value) => {
            const numberValidation = valid.number(value);
            return numberValidation.isValid
              ? Promise.resolve()
              : Promise.reject("Invalid card number");
          },
        },
      ];
    case fieldNames.cardName:
      return [
        ...baseRules,
        {
          validator: async (_, value) => {
            const numberValidation = valid.cardholderName(value);
            return numberValidation.isValid
              ? Promise.resolve()
              : Promise.reject("Invalid card holder name");
          },
        },
      ];
    case fieldNames.cardValidity:
      return [
        ...baseRules,
        {
          validator: async (_, value) => {
            const [month, year] = value.split(" / ");
            const century = new Date().getFullYear().toString().slice(0, 2);
            const date = new Date(century + year, month);
            const isValid = date > new Date();

            return isValid
              ? Promise.resolve()
              : Promise.reject("Invalid expiration date");
          },
        },
      ];
    case fieldNames.cardSecurityCode:
      return [
        ...baseRules,
        {
          min: 3,
          message: "Invalid Card Security Code",
        },
        {
          pattern: new RegExp("^[0-9]+$"),
          message: "Invalid Card Security Code",
        },
      ];
    default:
      return baseRules;
  }
};
