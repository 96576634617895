export const fieldNames = {
  name: "name",
  email: "email",
  amount: "amount",
  address: "address",
  cardNumber: "cardNumber",
  cardName: "cardName",
  cardSecurityCode: "cardSecurityCode",
  cardValidity: "cardValidity",
  country: "country",
  city: "city",
  pincode: "pincode",
  state: "state",
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  currency: "currency",
  paymentMethod: "paymentMethod",
};
