import React from "react";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
import ChargeCard from "./../ChargeCard";
import StripeGateway from "../StripeGateway/StripeGateway";
import QuestionBox from "../../UI/QuestionBox";
import PaymentError from "../../UI/PaymentError";
import { stepsData } from "../../../constants/Steps";

const Payment = () => {
  const { pg, globalIntentData, paymentError, step } =
    useSubscriptionContext();
  const renderPg = () => {
    switch (pg) {
      case "AUTHNET":
        return <ChargeCard />;
      case "STRIPE":
        return <StripeGateway />;
      default:
        return <></>;
    }
  };

  return (
    <>
      {paymentError && <PaymentError paymentError={paymentError}/>}
      {globalIntentData.paymentMethod === "CARD" ? (
        <QuestionBox step={step} stepsData={stepsData} />
      ) : (
        <div className="py-4"></div>
      )}{" "}
      {renderPg()}
    </>
  );
};

export default Payment;
