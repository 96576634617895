import axios from "../../axios";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const useTriggerIntent = () => {
  const {
    subscriptionId,
    globalIntentData,
    currency,
    setAmount,
    setCurrency,
    setInterval,
    setIntervalCount,
    setPg,
    setCallbackUrl,
    setPgPublicKey,
    setLoading,
    setMerchantName,
    setStatus,
    setClientSecret,
  } = useSubscriptionContext();
  const triggerIntent = async () => {
    const urlParam = new URLSearchParams(window.location.search);
    setLoading(true);
    try {
      const { data } = await axios.post(`/subscription/trigger`, {
        subscriptionId: subscriptionId,
        currency: currency || urlParam.get("currency"),
        paymentMethod:
          globalIntentData?.paymentMethod || urlParam.get("paymentMethod"),
      });

      // setInterval(data?.interval);
      // setIntervalCount(data?.intervalCount);
      setPg(data?.pg);
      setCallbackUrl(data?.callbackUrl);
      setPgPublicKey(data?.pgPublicKey);
      setAmount(data?.presentmentAmount);
      setCurrency(data?.presentmentCurrency);
      // setMerchantName(data?.merchantName);
      setClientSecret(data?.pgClientSecret);

      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { triggerIntent };
};

export default useTriggerIntent;
