import React from "react";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { stepsData } from "../../constants/Steps";
import { Button } from "antd";
import { FaAnglesRight } from "react-icons/fa6";
import usePaymentMethod from "../../hooks/OneTimePayment/usePaymentMethod";
import useTriggerIntent from "../../hooks/OneTimePayment/useTriggerIntent";
import useTriggerPayment from "../../hooks/OneTimePayment/useTriggerPayment";

const ProceedButton = () => {
  const { step, setStep, form } = useOneTimePaymentsContext();
  const { getPaymentMethod, postIngestBillingAddress } = usePaymentMethod();
  const { triggerIntent } = useTriggerIntent();
  const { triggerPayment, payBtnLoading } = useTriggerPayment();

  const handleContinue = async () => {
    await form
      .validateFields()
      .then((values) => {
        nextStep();
      })
      .catch((errorInfo) => {
        const fieldElement = document.querySelector(
          `[name="${errorInfo.errorFields[0].name}"]`
        );
        if (fieldElement) {
          fieldElement.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
            marginTop: "-100px",
          });
        }
      });
  };

  const nextStep = async () => {
    switch (step) {
      case 0:
        await postIngestBillingAddress();
        await getPaymentMethod();
        setStep(step + 1);
        break;

      case 1:
        await triggerIntent();
        setStep(step + 1);
        break;

      case 2:
        await triggerPayment();
        break;

      default:
        setStep(step + 1);
        break;
    }
  };

  return (
    <div className="my-6">
      <Button
        size="large"
        className="font-semibold"
        block
        onClick={handleContinue}
        loading={payBtnLoading}
      >
        {step === stepsData.length - 1 ? (
          "Pay"
        ) : (
          <div className="flex items-center gap-2">
            Continue <FaAnglesRight />
          </div>
        )}
      </Button>
    </div>
  );
};

export default ProceedButton;
