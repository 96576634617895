import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement } from "@stripe/react-stripe-js";
import StripeCheckoutForm from "./StripeCheckoutForm";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
import ProceedButton from "../ProceedButton";
import TransDetails from "../TransDetails";

const StripeGateway = () => {
  const {
    clientSecret,
    pgPublicKey,
    currency,
    merchantName,
    setCurrency,
    currenciesOptions,
    currenciesOptionsList,
    setCurrenciesOptions,
  } = useSubscriptionContext();

  const appearance = {
    theme: "stripe",
    variables: {
      borderRadius: "2px",
      tabIconSelectedColor: "#fff",
      gridRowSpacing: "24px",
      fontSizeSm: "14px",
    },
    rules: {
      ".Input, .Block, .CheckboxInput, .CodeInput": {
        boxShadow: "0px 0px 0px 0px",
        borderRadius: "8px",
      },
      ".Tab--selected, .Tab--selected:hover": {
        backgroundColor: "#fff",
      },
      ".Label": {
        paddingBottom: "12px",
        marginBottom: "0px",
      },
      ".Error": {
        color: "#ff4d4f",
      },
      ".Input--invalid": {
        borderColor: "#ff4d4f",
      },
    },
  };

  const options = {
    clientSecret: clientSecret,
    appearance: appearance,
  };

  const stripePromise = loadStripe(pgPublicKey);

  return (
    <div>
      <Elements stripe={stripePromise} options={options}>
        <StripeCheckoutForm PaymentElement={PaymentElement} />
      </Elements>
    </div>
  );
};

export default StripeGateway;
