import React from "react";
import Header from "./Header";
import Details from "./Details";

const ContainerCCWTP = () => {
  return (
    <div
      className="bg-white h-full w-full max-w-[32rem] drop-shadow-2xl rounded-2xl sm:py-9 sm:px-12 pt-8 pb-6 px-6 backdrop-blur shadow-2xl"
      id="container"
    >
      <Header />
      <div className="pt-4 my-4">
        <div className="flex flex-col gap-2">
          <div className="text-lg font-medium">Please enter your details</div>
        </div>
      </div>
      <Details />
    </div>
  );
};

export default ContainerCCWTP;
