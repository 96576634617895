import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";

const useTriggerPayment = () => {
  const {
    pg,
    globalIntentData,
    subscriptionId,
    stripe,
    stripeElement,
    setPaymentError,
    setStatus,
    callbackUrl,
    currency,
    pgPublicKey,
    setErrorMessage
  } = useSubscriptionContext();

  const [payBtnLoading, setPayBtnLoading] = useState(false);

  const triggerPayment = async () => {
    switch (pg) {
      case "AUTHNET":
        await chargeCard();
        break;
      case "STRIPE":
        await stripePay();
        break;
      default:
        break;
    }
  };

  const chargeCard = async () => {
    const cardValidity = globalIntentData?.cardValidity.split(" / ");
    const cardNumber = globalIntentData?.cardNumber.replace(/\s/g, "");
    const cardDetails = {
      cardNumber: cardNumber,
      cardHolderName: globalIntentData?.cardName,
      expiryMonth: cardValidity[0],
      expiryYear: cardValidity[1],
      cardCode: globalIntentData?.cardSecurityCode,
    };

    setPayBtnLoading(true);

    try {
      const { data } = await axios.post(`/payments/charge-card`, {
        subscriptionId: subscriptionId,
        cardDetails: cardDetails,
      });

      if (data?.status === "SUCCESS") {
        setStatus("SUCCESS");
      } else {
        setPaymentError(data?.errorMessage);
      }
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setPayBtnLoading(false);
    }
  };

  const stripePay = async () => {
    const elements = stripeElement;
    if (!stripe || !elements) {
      return;
    }

    setPayBtnLoading(true);

    const { error, setupIntent } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url:
          window.location.href +
          `&callbackUrl=${callbackUrl}&paymentMethod=${globalIntentData.paymentMethod}&currency=${currency}&pgPublicKey=${pgPublicKey}`,
      },
      redirect: "if_required",
    });

    if (error) {
      if (error.type === "card_error" || error.type === "validation_error") {
        setPaymentError(error.message);
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    } else if (setupIntent) {
      if (setupIntent.status === "succeeded") {
        try {
          const { data } = await axios.post(
            `/subscription/trigger-ack/${subscriptionId}`,
            {}
          );
          setStatus(data.status);
          data.errorMessage && setErrorMessage(data.errorMessage);
        } catch (error) {
          ErrorHandler(error, setStatus);
        }
      } else {
        setPaymentError(
          "Your payment was declined by the payment method provider."
        );
      }
    }

    setPayBtnLoading(false);
  };

  return { triggerPayment, payBtnLoading };
};

export default useTriggerPayment;
