import Loader from "../UI/Loader";
import sww500Animations from "../../assets/Animations/Something500Animation.json";

const PaymentStatusError = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
      <Loader
        json={sww500Animations}
        width="500px"
        height="250px"
        loop={false}
        keepLastFrame={true}
        mobileHeight="200px"
        mobileWidth="300px"
      />
      <p className="pt-8 mb-4 text-lg text-center">Something went wrong!</p>
    </div>
  );
};

export default PaymentStatusError;
