import React from "react";

const GridBackground = ({ children }) => {

  return (
    <div className="overflow-hidden">
      <div className="h-[100vh] w-[100vw] bg-white relative flex items-center justify-center bg-grid-black/[0.1]">
        {/* Blur focus add the center */}
        <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_1%,black)]"></div>

        {/* Gradients */}
        {/* <div class="absolute inset-0 w-32 top-[40%] left-[10%] rotate-[-45deg] bg-gradient-to-r from-green-400 via-blue-400 to-green-600 rounded-full blur-3xl opacity-30"></div>
        <div class="absolute inset-0 w-32 top-[10%] left-[45%] rotate-[-45deg] bg-gradient-to-r from-blue-400 via-blue-600 to-green-600  rounded-full blur-3xl opacity-30"></div>
        <div class="absolute inset-0 w-32 top-[0%] left-[90%] rotate-[-45deg] bg-gradient-to-r from-blue-400 via-blue-600 to-green-600  rounded-full blur-3xl opacity-30"></div> */}

        <div
          className="bg-white min-h-screen h-full w-full max-w-[34rem] drop-shadow-2xl rounded-2xl py-10 px-12 sm:px-16 xl:px-20 backdrop-blur lg:shadow-2xl overflow-y-auto"
          id="container"
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default GridBackground;
