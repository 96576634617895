/* eslint-disable react-hooks/exhaustive-deps */
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";

const useCalculateAmount = () => {
  const { xIntentId, setStatus, setCurrency, amount, setAmount } =
    useOneTimePaymentsContext();
  const [amountLoading, setAmountLoading] = useState(false);

  const getCalculatedAmount = async ({value}) => {
    setAmountLoading(true);
    try {
      const { data } = await axios.post(
        `/payments/v2/calculateAmount/${xIntentId}`,
        { currency: value }
      );

      setAmount(data.amount);
      setCurrency(data.currency);
      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setAmountLoading(false);
    }
  };

  return { amount, amountLoading, getCalculatedAmount };
};

export default useCalculateAmount;
