import React from "react";

const GridBackground = ({ children }) => {
  return (
    <div className="overflow-hidden">
      <div className="h-full sm:h-[100vh]  w-[100vw] bg-white flex items-center justify-center bg-grid-black/[0.1]">
        {/* Blur focus add the center */}
        <div className="absolute pointer-events-none inset-0 flex items-center justify-center bg-white [mask-image:radial-gradient(ellipse_at_center,transparent_1%,black)]"></div>
        {children}
      </div>
    </div>
  );
};

export default GridBackground;
