/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useSubscriptionContext } from "../../../contexts/SubscriptionContext";
import usePaymentConfirmation from "../../../hooks/Subscription/usePaymentConfirmation";

const StripeCheckoutForm = () => {
  const {
    setStripe,
    setStripeElement,
    setStripeCardElement,
    stripeFormFilled,
    setIsPaymentBtnDisabled,
    setStripeFormFilled,
    loading,
    setLoading,
  } = useSubscriptionContext();
  const { stripeConfirmation } = usePaymentConfirmation();

  const stripe = useStripe();
  const elements = useElements();
  setStripe(stripe);
  setStripeElement(elements);

  useEffect(() => {
    stripeFormFilled &&
      setStripeCardElement(elements.getElement(PaymentElement));
  }, [stripeFormFilled]);

  const stripeConfirmationHandler = async () => {
    setLoading(true);
    await stripeConfirmation({ stripe });
    setLoading(false);
  };

  useEffect(() => {
    stripeConfirmationHandler();
  }, [stripe]);

  return (
    <>
      {!loading && (
        <div className="pb-4">
          <PaymentElement
            onChange={(event) => {
              setStripeFormFilled(event.complete);
              setIsPaymentBtnDisabled(!event.complete);
            }}
          />
        </div>
      )}
    </>
  );
};

export default StripeCheckoutForm;
