import React from "react";
import { ReactComponent as XpayLogo } from "../../assets/xPayLogoWhite.svg";

const LandingWrapper = ({ children }) => {
  return (
    <>
      <div className="landing-cont">
        <div className="stack">
          <a href={"https://xpaycheckout.com/"} target="_blank" rel="noreferrer">
            <XpayLogo className="xpay-logo" />
          </a>
          <p className="logo-subtext">Global Access, Simplified</p>
          {children}
        </div>
      </div>
    </>
  );
};

export default LandingWrapper;
