import { captureException } from "@sentry/react";
import { toast } from "react-hot-toast";
import ErrorToast from "../components/UI/Toasts/ErrorToast";

export const ErrorHandler = (error, setStatus) => {
  captureException(error);
  setStatus("ERROR");

  if (process.env.REACT_APP_XPAY_ENV !== "prod") {
    toast.custom(
      (t) => (
        <ErrorToast
          t={t}
          message={
            error?.response?.data?.errorDescription ||
            error.message ||
            "Something went wrong"
          }
        />
      ),
      {
        id: "errorToast",
      }
    );
    console.error(error);
  }
};
