import React from "react";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import ChargeCard from "./../ChargeCard";
import StripeGateway from "../StripeGateway/StripeGateway";
import PaymentError from "../../UI/PaymentError";

const Payment = () => {
  const { intentDetails, paymentError } = useOneTimePaymentsContext();

  const renderPg = () => {
    switch (intentDetails.pg) {
      case "AUTHNET":
        return <ChargeCard />;
      case "STRIPE":
        return <StripeGateway />;
      default:
        return <></>;
    }
  };

  return (
    <div
      className={`overflow-y-auto overflow-x-hidden sm:h-[calc(100vh-473px)] sm:px-2 flex flex-col gap-4 relative`}
    >
      {paymentError && <PaymentError paymentError={paymentError} />}
      {renderPg()}
    </div>
  );
};

export default Payment;
