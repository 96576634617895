import React from "react";
import toast from "react-hot-toast";
import { CgClose } from "react-icons/cg";
import { ReactComponent as WarningIcon } from "../../../assets/Icons/toast/warning.svg";

const WarningToast = ({ t, message }) => {
  return (
    <div
      className={`${
        t.visible ? "animate-enter" : "animate-leave"
      } flex items-start justify-between p-4 rounded-lg shadow-md bg-[#FFF7ED] border border-[#FFF1DF] sm:w-[416px] w-[364px] text-xs sm:text-[16px] leading-6`}
    >
      <div className="flex items-start gap-4">
        <div className="w-5 h-5">
          <WarningIcon />
        </div>
        <span>{message}</span>
      </div>
      <button
        onClick={() => toast.dismiss(t.id)}
        className="ml-4 text-gray-500 hover:text-gray-700"
      >
        <CgClose className="w-4 h-4" />
      </button>
    </div>
  );
};

export default WarningToast;
