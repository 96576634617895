/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import YourDetails from "./Steps/YourDetails";
import Payment from "./Steps/Payment";
import Method from "./Steps/Method";
import { Form } from "antd";

const StepContent = () => {
  const { step, setForm, globalIntentData, loading } = useSubscriptionContext();

  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue(globalIntentData);
    setForm(form);
  }, [loading, globalIntentData, form]);

  const renderStep = () => {
    switch (step) {
      case 0:
        return <YourDetails />;
      case 1:
        return <Method />;
      case 2:
        return <Payment />;
      default:
        return <YourDetails />;
    }
  };

  return (
    <div>
      <Form
        form={form}
        name={`step-${step}`}
        className=""
        initialValues={globalIntentData}
        layout="vertical"
        autoComplete="on"
        requiredMark={"optional"}
      >
        {renderStep()}
      </Form>
    </div>
  );
};

export default StepContent;
