/* eslint-disable react-hooks/exhaustive-deps */
import axios from "../../axios";
import { fieldNames } from "../../constants/fieldNames";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { Country } from "country-state-city";
import { useOneTimePaymentsContext } from "../../contexts/OneTimePaymentsContext";
import { useEffect, useState } from "react";
import useGetPaymentMethod from "../../hooks/OneTimePayment/usePaymentMethod";
import usePostIngestBillingAddress from "../../hooks/OneTimePayment/usePostIngestBillingAddress";
import { loadStripe } from "@stripe/stripe-js";
const usePrefetchCurrencies = () => {
  const {
    xIntentId,
    setCurrency,
    setStatus,
    merchantName,
    setMerchantName,
    setLoading,
    setLoadingMain,
    setMode,
    setAmount,
    setIntentDetails,
    intentDetails,
    setPaymentMethod,
    setCallbackUrl,
  } = useOneTimePaymentsContext();

  const [currenciesOptionsList, setCurrenciesOptionsList] = useState([]);
  const [globalIntentData, setGlobalIntentData] = useState();

  const { getPaymentMethod, paymentMethodList } = useGetPaymentMethod();
  const { postIngestBillingAddress } = usePostIngestBillingAddress();

  const urlParam = new URLSearchParams(window.location.search);
  const clientSecret = urlParam.get("payment_intent_client_secret");

  const getCurrencySymbol = (currency) => {
    let symbol = Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
      minimumFractionDigits: 0,
    }).format(0);

    symbol = symbol.replace(/\d/g, "");

    return currency === symbol.slice(0, -1)
      ? currency
      : `${currency} (${symbol})`;
  };

  const clearUrlParams = () => {
    const url = new URL(window.location.href);
    const searchParams = new URLSearchParams(url.search);
    searchParams.delete("currency");
    searchParams.delete("amount");
    searchParams.delete("paymentMethod");
    searchParams.delete("payment_intent");
    searchParams.delete("pgPublicKey");
    searchParams.delete("payment_intent_client_secret");
    searchParams.delete("redirect_status");
    searchParams.delete("callbackUrl");
    url.search = searchParams.toString();
    window.history.replaceState({}, "", url.toString());
  };

  useEffect(() => {
    const getPrefetchCurrencies = async () => {
      setLoadingMain(true);
      try {
        if (clientSecret) {
          const stripe = await loadStripe(urlParam.get("pgPublicKey"));

          const { paymentIntent } = await stripe.retrievePaymentIntent(
            clientSecret
          );
          
          if (paymentIntent.status === "succeeded") {
            setCallbackUrl(urlParam.get("callbackUrl"));
            setIntentDetails({
              ...intentDetails,
              xIntentId: urlParam.get("xpay_intent_id"),
              callbackUrl: urlParam.get("callbackUrl"),
            });
            setStatus("SUCCESS");
            clearUrlParams();
            return;
          }
        }

        const { data } = await axios.post(
          `/payments/v2/prefetchCurrencies/${xIntentId}`
        );

        setAmount(data?.primary?.amount);
        setCurrency(data?.primary?.currency);
        setMerchantName(data?.merchantName);
        setMode(data?.mode);
        setCallbackUrl(data?.callbackUrl);

        setGlobalIntentData({
          ...globalIntentData,
          [fieldNames.name]: data?.name,
          [fieldNames.email]: data?.email,
          [fieldNames.pincode]: data?.pinCode,
          [fieldNames.country]: data?.country
            ? data?.country +
              "-" +
              Country.getCountryByCode(data?.country)?.name
            : null,
        });

        let currenciesOptionsList = [];

        currenciesOptionsList.push({
          label: getCurrencySymbol(data.primary.currency),
          value: data.primary.currency,
        });

        data.others.forEach((element) => {
          currenciesOptionsList.push({
            label: getCurrencySymbol(element),
            value: element,
          });
        });

        setCurrenciesOptionsList(currenciesOptionsList);

        if (clientSecret) {
          setPaymentMethod(urlParam.get("paymentMethod"));
          setCurrency(urlParam.get("currency"));
          setAmount(urlParam.get("amount"));

          setIntentDetails({
            ...intentDetails,
            pg: "STRIPE",
            clientSecret: clientSecret,
            pgPublicKey: urlParam.get("pgPublicKey"),
            xIntentId: urlParam.get("xpay_intent_id"),
            callbackUrl: urlParam.get("callbackUrl"),
          });
        }

        await getPaymentMethod(
          urlParam.get("currency") || data?.primary?.currency
        );

        if (data?.name && data?.email && data?.pinCode && data?.country) {
          const values = {
            [fieldNames.name]: data?.name,
            [fieldNames.email]: data?.email,
            [fieldNames.pincode]: data?.pinCode,
            [fieldNames.country]: data?.country,
          };

          await postIngestBillingAddress(values, false);
        }

        setLoading(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      } finally {
        setLoadingMain(false);
      }
    };
    getPrefetchCurrencies();
  }, []);

  return {
    merchantName,
    currenciesOptionsList,
    globalIntentData,
    setGlobalIntentData,
    getPaymentMethod,
    paymentMethodList,
  };
};

export default usePrefetchCurrencies;
