import React from "react";
import { FaAngleRight } from "react-icons/fa6";

const Breadcrumb = ({ step, setStep, stepsData }) => {
  const handleNavigation = (index) => {
    index < step && setStep(index);
  };

  return (
    <div className="flex gap-4 py-8 text-sm font-medium">
      {stepsData.map((item, index) => {
        return (
          <div className={`flex items-center gap-4`} key={index}>
            <span
              className={`
                ${
                  step === index
                    ? "text-sky-500 font-semibold"
                    : "text-slate-700"
                }
                ${index < step && "cursor-pointer"}
              }`}
              onClick={() => handleNavigation(index)}
            >
              {item.title}
            </span>
            {index !== stepsData.length - 1 && (
              <FaAngleRight className="opacity-50" />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
