import Loader from "../../UI/Loader";
import expiredAnimation from "../../../assets/Animations/paymentlinkexpired.json";
import GridBackground from "../../UI/GridBackground";
import Header from "../../UI/Header";

const PaymentExpired = () => {
  return (
    <GridBackground>
      <div
        className="bg-white h-full w-full max-w-[32rem] drop-shadow-2xl sm:rounded-2xl sm:py-9 sm:px-12 pt-8 pb-6 px-6 backdrop-blur shadow-2xl "
        id="container"
      >
        <Header />
        <div className="flex flex-col items-center justify-center h-[75vh]">
          <Loader
            json={expiredAnimation}
            width="400px"
            height="300px"
            loop={false}
            keepLastFrame={true}
            mobileHeight="250px"
            mobileWidth="300px"
          />
          <div className="pt-8 ">
            <p className="text-xl text-center">
              This payment link has expired.
            </p>
            <p className="mt-1 text-sm text-center text-[#838383]">
              Please request a new one to complete payment.
            </p>
          </div>
        </div>
      </div>
    </GridBackground>
  );
};

export default PaymentExpired;
