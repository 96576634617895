/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";

export const useGetMerchantLogo = ({
  subscriptionId,
  setStatus,
  setLoadingMain,
}) => {
  const [merchantLogo, setMerchantLogo] = useState(null);

  useEffect(() => {
    const getMerchantLogo = async () => {
      try {
        const { data } = await axios.get(
          `/subscription/get-merchant-logo/${subscriptionId}`
        );
        setMerchantLogo(data?.merchantLogo);

        if (data?.merchantLogo === null) setLoadingMain(false);
      } catch (error) {
        ErrorHandler(error, setStatus);
      }
    };

    getMerchantLogo();
  }, []);

  return { merchantLogo };
};
