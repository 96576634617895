/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import InputField from "./InputField";
import { fieldNames } from "../../constants/fieldNames";
import { Form, Button } from "antd";
import InputNumberField from "./InputNumberField";
import { useAMACreateIntent } from "../../hooks/CustomerChooseWhatToPay/useAMACreateIntent";
import { Divider } from "antd";

import { IoMdInformationCircleOutline } from "react-icons/io";

const Details = () => {
  const [form] = Form.useForm();
  const { createAMAIntent, loading } = useAMACreateIntent();

  const onFinish = async (values) => {
    await createAMAIntent(values);
  };

  return (
    <div className="flex flex-col gap-5 mt-8 font-sans">
      <Form
        form={form}
        name={`AMACreateIntentForm`}
        className="font-sans"
        layout="vertical"
        autoComplete="on"
        requiredMark={"optional"}
        onFinish={onFinish}
      >
        <InputField
          name={fieldNames.name}
          label="Name"
          required
          placeholder={"Enter your name"}
        />
        <InputField
          name={fieldNames.email}
          label="Email"
          required
          placeholder={"Enter your email"}
        />
        <InputNumberField
          name={fieldNames.amount}
          label="Amount"
          required
          placeholder={"Enter your amount"}
          prefix={"₹"}
          addonAfter={"INR"}
        />
        {/* <div className="text-[#374151] flex items-center gap-2">
          <IoMdInformationCircleOutline />
          Platform fee may be added to the final amount
        </div> */}
        <Divider />
        <div className="text-[16px]">
          Paying to <strong>AskMeAssignment</strong>
        </div>
        <Form.Item noStyle>
          <Button
            size="large"
            className="mt-4 font-sans font-semibold"
            block
            loading={loading}
            htmlType="submit"
          >
            Continue
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Details;
