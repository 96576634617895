/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useOneTimePaymentsContext } from "../../../contexts/OneTimePaymentsContext";
import usePaymentConfirmation from "../../../hooks/OneTimePayment/usePaymentConfirmation";

const StripeCheckoutForm = () => {
  const {
    setStripe,
    setStripeElement,
    setIsPaymentBtnDisabled,
    setStripeFormFilled,
    loading,
    setLoading,
    intentDetails,
  } = useOneTimePaymentsContext();
  const { stripeConfirmation } = usePaymentConfirmation();

  const stripe = useStripe();
  const elements = useElements();
  setStripe(stripe);
  setStripeElement(elements);

  const stripeConfirmationHandler = async () => {
    setLoading(true);
    await stripeConfirmation({ stripe });
    setLoading(false);
  };

  useEffect(() => {
    stripeConfirmationHandler();
  }, [stripe]);

  return (
    <>
      {!loading && (
        <div className="pb-4">
          <PaymentElement
            options={{
              defaultValues: {
                billingDetails: {
                  name: intentDetails?.billingDetails?.name,
                  email: intentDetails?.billingDetails?.email,
                },
              },
            }}
            onChange={(event) => {
              setStripeFormFilled(event.complete);
              setIsPaymentBtnDisabled(!event.complete);
            }}
          />
        </div>
      )}
    </>
  );
};

export default StripeCheckoutForm;
