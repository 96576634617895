import React from "react";
import GridBackground from "./components/UI/GridBackground";
import Container from "./components/Subscription/Container";
import { SubscriptionProvider } from "./contexts/SubscriptionContext";

const Subscriptions = () => {
  return (
    <div>
      <GridBackground>
        <SubscriptionProvider>
          <Container />
        </SubscriptionProvider>
      </GridBackground>
    </div>
  );
};

export default Subscriptions;
