/* eslint-disable react-hooks/exhaustive-deps */
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import axios from "../../axios";
import { ErrorHandler } from "../../utils/ErrorHandler";
import { useState } from "react";

const useCalculateAmount = () => {
  const {
    subscriptionId,
    setStatus,
    setCurrency,
    amount,
    setAmount,
  } = useSubscriptionContext();

  const [amountLoading, setAmountLoading] = useState(false);

  const getCalculatedAmount = async ({value}) => {
    setAmountLoading(true);
    try {
      const { data } = await axios.post(
        `/subscription/calculate-amount/${subscriptionId}`,
        { currency: value }
      );

      setAmount(data.amount);
      setCurrency(data.currency);
      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setAmountLoading(false);
    }
  };

  return { amount, amountLoading, getCalculatedAmount };
};

export default useCalculateAmount;
