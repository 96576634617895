import { CiCreditCard1 } from "react-icons/ci";
import amazonPay from "../assets/paymentMethods/amazon-pay.png";
import applePay from "../assets/paymentMethods/apple-pay.png";
import CashAppPay from "../assets/paymentMethods/Cash-App.png";
import Affirm from "../assets/paymentMethods/affirm.webp";
import klarna from "../assets/paymentMethods/klarna.svg";

export const paymentMethod = [
  {
    id: "CARD",
    title: "Card",
    description: "Pay securely using your credit or debit card.",
    icon: <CiCreditCard1 size={28} />,
    value: "CARD",
    showText: true,
  },
  {
    id: "AMAZON_PAY",
    title: "Amazon Pay",
    description: "Use your Amazon account for a fast and secure checkout.",
    icon: <img src={amazonPay} alt="amazonPay" className="w-8 mt-2" />,
    value: "AMAZON_PAY",
    showText: true,
  },
  {
    id: "APPLE_PAY",
    title: "Apple Pay",
    description:
      "Make payments effortlessly with Apple Pay on supported devices.",
    icon: <img src={applePay} alt="applePay" className="mt-1 w-9" />,
    value: "APPLE_PAY",
    showText: true,
  },
  {
    id: "CASH_APP",
    title: "Cash App",
    description: "Pay using Cash App for a quick and easy transaction.",
    icon: <img src={CashAppPay} alt="cashAppPay" className="w-7" />,
    value: "CASH_APP",
    showText: true,
  },
  {
    id: "AFFIRM",
    title: "Affirm",
    description: "Pay using Affirm for a quick and easy transaction.",
    icon: <img src={Affirm} alt="affirm" className="w-12" />,
    value: "AFFIRM",
    showText: false,
  },
  {
    id: "KLARNA",
    title: "Klarna",
    description: "Pay using klarna for a quick and easy transaction.",
    icon: <img src={klarna} alt="klarna" className="w-16" />,
    value: "KLARNA",
    showText: false,
  },
];
