import axios from "../../axios";
import { useSubscriptionContext } from "../../contexts/SubscriptionContext";
import { ErrorHandler } from "../../utils/ErrorHandler";

const useTriggerIntent = () => {
  const {
    subscriptionId,
    setAmount,
    setCurrency,
    setPg,
    setCallbackUrl,
    setPgPublicKey,
    setLoading,
    setMerchantName,
    setStatus,
    setClientSecret,
    setLoadingMethods,
    setIntentDetails
  } = useSubscriptionContext();
  const triggerIntent = async ({ value, currency }) => {
    setLoadingMethods(true);
    try {
      const { data } = await axios.post(`/subscription/trigger`, {
        subscriptionId: subscriptionId,
        currency: currency,
        paymentMethod: value,
      });

      setPg(data?.pg);
      setIntentDetails(data);
      setCallbackUrl(data?.callbackUrl);
      setPgPublicKey(data?.pgPublicKey);
      setAmount(data?.presentmentAmount);
      setCurrency(data?.presentmentCurrency);
      setMerchantName(data?.merchantName);
      setClientSecret(data?.pgClientSecret);

      await new Promise((resolve) => setTimeout(resolve, 100));
    } catch (error) {
      ErrorHandler(error, setStatus);
    } finally {
      setLoading(false);
    }
  };

  return { triggerIntent };
};

export default useTriggerIntent;
