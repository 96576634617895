/* eslint-disable react-hooks/exhaustive-deps */
import Loader from "../UI/Loader";
import { useEffect, useState } from "react";
import successAnimation from "../../assets/Animations/successAnimation.json";
import { ImSpinner2 } from "react-icons/im";

const PaymentStatusSuccess = ({ triggerRedirect, loadingMain }) => {
  const [showMessage, setShowMessage] = useState(false);

  useEffect(() => {
    if (!loadingMain) {
      const timeout = setTimeout(() => {
        setShowMessage(true);
      }, 2000);

      triggerRedirect();
      return () => clearTimeout(timeout);
    }
  }, [loadingMain]);

  return (
    <>
      {!loadingMain && (
        <div className="flex flex-col items-center justify-center gap-2 h-[75vh] drop-shadow-xl">
          <Loader
            json={successAnimation}
            width="500px"
            height="250px"
            loop={false}
            keepLastFrame={true}
            mobileHeight="250px"
            mobileWidth="500px"
          />
          <div className="mb-8 text-xl">Thank you for your payment!</div>
          {
            <p
              className={`${
                showMessage ? "flex" : "hidden"
              } text-gray-500 text-sm justify-center items-center gap-2`}
            >
              Redirecting to your website...{" "}
              <ImSpinner2 className="animate-spin" />
            </p>
          }
        </div>
      )}
    </>
  );
};

export default PaymentStatusSuccess;
